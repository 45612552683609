<template>
  <v-container fluid class="pa-0">
    <v-dialog :value="loadingDialog" persistent max-width="320px">
      <v-list>
        <v-list-tile
          v-for="(item, index) in loadingData"
          :key="index"
          avatar
          :color="item.error ? 'red' : item.color"
        >
          <v-list-tile-avatar>
            <v-progress-circular
              :value="100"
              :size="30"
              :color="item.error ? 'red' : item.color"
              :indeterminate="item.loading"
            ></v-progress-circular>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-dialog>
    <template v-if="!loadingDialog">
      <v-card>
        <v-card-title>
          <v-select
            label="Тип изменения"
            :items="typeEdit"
            item-text="name"
            item-value="id"
            v-model.number="filter.purchase_type_id"
            hide-details
            clearable
            class="pr-4"
            @change="changeFilter"
          ></v-select>
          <v-autocomplete
            label="Goods"
            :items="goodsList"
            item-text="name"
            item-value="id"
            v-model.number="filter.good_id"
            hide-details
            no-data-text="Не надено"
            clearable
            class="scs-small"
            :search-input.sync="searchGoods"
            @change="changeFilter"
          ></v-autocomplete>
          <v-menu
            :close-on-content-click="false"
            v-model="dataStartPicker"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="200px"
            class="ml-4"
          >
            <v-text-field
              v-model="filter.start_date"
              slot="activator"
              label="C"
              prepend-icon="event"
              hide-details
              readonly
              clearable
            ></v-text-field>
            <v-date-picker
              v-model="filter.start_date"
              @input="dataStartPicker = false"
              no-title
              scrollable
              locale="ru-ru"
              first-day-of-week="1"
              @change="changeFilter"
            ></v-date-picker>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            v-model="dataEndPicker"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="200px"
            class="ml-4"
          >
            <v-text-field
              v-model="filter.end_date"
              slot="activator"
              label="По"
              prepend-icon="event"
              hide-details
              readonly
              clearable
            ></v-text-field>
            <v-date-picker
              v-model="filter.end_date"
              @input="dataEndPicker = false"
              no-title
              scrollable
              locale="ru-ru"
              first-day-of-week="1"
              @change="changeFilter"
            ></v-date-picker>
          </v-menu>
        </v-card-title>

        <v-data-table
          :headers="headersTable"
          :items="purchaseList"
          hide-actions
          no-data-text="Поставок не найдено"
          no-results-text="Поставок не найдено"
          :loading="tableLoading"
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <td>{{ new Date(props.item.created_at).toLocaleString() }}</td>
            <td>
              {{ typeEdit.find((item) => item.id === props.item.purchase_type_id).name }}
            </td>
            <td>{{ props.item.arrival }}</td>
            <td>{{ props.item.amount }}</td>
            <td>{{ markup(props.item) }}%</td>
            <td>{{ props.item.revaluation }}</td>
            <td>
              <template v-if="props.item.client">
                {{ props.item.client.name }}
              </template>
            </td>
            <td>
              <template v-if="props.item.user">
                {{ props.item.user.name }}
              </template>
            </td>

            <td class="text-xs-right" style="width: 7%;">
              <v-icon
                class="mr-2"
                @click.prevent="
                  $router.push({
                    name: 'historyView',
                    params: { id: props.item.id }
                  })
                "
              >
                visibility
              </v-icon>
            </td>
          </template>
        </v-data-table>
        <v-layout row wrap justify-space-around class="py-2">
          <v-flex xs2 class="px-3">
            <v-text-field
              label="Количество на странице"
              v-model.number="take"
              hide-details
              @change="changeShowElem()"
            ></v-text-field>
          </v-flex>
          <v-flex xs10 class="text-xs-right px-3">
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="page === 1"
              @click="prevPage()"
            >
              <v-icon dark>keyboard_arrow_left</v-icon>
            </v-btn>
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="purchaseList.length < take"
              @click="nextPage()"
            >
              <v-icon dark>keyboard_arrow_right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'History',
  data() {
    return {
      filter: {
        purchase_type_id: '',
        start_date: '',
        end_date: '',
        good_id: null,
      },
      typeEdit: [],
      goodsList: [],
      searchGoods:'',
      dataStartPicker: null,
      dataEndPicker: null,
      loadingData: [
        {
          title: 'Получение поставок',
          error: false,
          loading: false,
          color: 'indigo',
          id: 'purchase',
        },
      ],
      headersTable: [
        {
          text: 'Дата',
          align: 'left',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Тип изменения',
          align: 'left',
          sortable: true,
          value: 'purchase_type_id',
        },
        {
          text: 'Закупка',
          align: 'left',
          sortable: true,
          value: 'arrival',
        },
        {
          text: 'Приход',
          align: 'left',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Наценка',
          align: 'left',
          sortable: true,
          value: 'markup',
        },
        {
          text: 'Переоценка',
          align: 'left',
          sortable: true,
          value: 'revaluation',
        },
        {
          text: 'Компания',
          align: 'left',
          sortable: true,
          value: 'company_name',
        },
        {
          text: 'Менеджер',
          align: 'left',
          sortable: true,
          value: 'user_name',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'action',
        },
      ],
      purchaseList: [],
      timerGoods: null,
      take: 20,
      page: 1,
      pagination: {
        sortBy: 'id',
        rowsPerPage: -1,
        descending: true,
      },
      tableLoading: false,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.page = 1;

        this.getItemsList();
      },
      deep: true,
    },
    searchGoods(val) {
      const findGoods = this.goodsList.find((item) => item.name === val);
      console.log(findGoods)
      if (findGoods) return false;

      if (val && val.length >= 3) {
        if (this.timerGoods) clearTimeout(this.timerGoods);

        this.timerGoods = setTimeout(() => {
          this.getGoods(val);
        }, 500);
      } else {
        this.goodsList = [];
      }
    },
  },
  computed: {
    loadingDialog: function loadingDialog() {
      const loadData = this.loadingData.filter(item => !item.error && !item.loading);
      return loadData.length === this.loadingData.length ? 0 : 1;
    },
  },
  methods: {
    getGoods(searchVal) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/goods`;

      axios
        .get(url, {
          params: {
            name: searchVal,
            store_id:parseInt(localStorage.getItem('store-id')),
            page_limit: 999,
          },
        })
        .then((response) => {
          this.goodsList = response.data.map((item) => {
            return {
              name: `${item.name} (${item.stock})`,
              id: item.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPurchaseTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/purchase-types`;

      axios
        .get(url)
        .then((response) => {
          this.typeEdit = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prevPage() {
      this.page -= 1;
      this.getItemsList();
    },
    nextPage() {
      this.page += 1;
      this.getItemsList();
    },
    changeShowElem() {
      localStorage.setItem('countElemPage', this.take);
      this.$store.commit('setCountElemPage', this.take);
      this.page = 1;
      this.getItemsList();
    },
    markup(item) {
      let markupVal = 0;
      if (item.amount !== 0) {
        markupVal = 100 - ((item.arrival * 100) / item.amount);
      }

      return +markupVal.toFixed(2);
    },
    changeFilter() {
      this.page = 1;
      this.getItemsList();
    },
    getItemsList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/purchases`;

      const getParams = {
        page: this.page,
        page_limit: this.take,
      };

      Object.keys(this.filter).forEach((code) => {
        if (this.filter[code]) {
          getParams[code] = this.filter[code];
        }
      });

      if (this.pagination.sortBy) {
        const sort = {};
        sort[this.pagination.sortBy] = (this.pagination.descending) ? 'desc_nulls_last' : 'asc_nulls_last';

        getParams.order_by = JSON.stringify(sort);
      }
      getParams.store_id = parseInt(localStorage.getItem('store-id')),
      axios
        .get(url, {
          params: getParams,
        })
        .then((response) => {
          const items = response.data;
          this.purchaseList = items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.take = this.$store.getters.getCountElemPage;

    this.getPurchaseTypes();
    this.getItemsList();
  },
};
</script>

<style lang="scss" scoped>
.v-table {
  tr:nth-child(even) {
    td {
      background: #f9f9f9;
    }

    &:hover {
      td {
        background: #eee;
      }
    }
  }
}
</style>
